<script setup>
import { isScreenLg } from '@@/utils/CommonUtils';
import { useUiStore } from '@@/stores/Ui';

defineProps({
  inLocationPage: {
    type: Boolean,
    default: false,
  },
  inMapPage: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();

const showBottomSheet = ref(false);
const showDialog = ref(false);
const weatherStationSlug = computed(() => uiStore.weatherStationSlug);

watch(weatherStationSlug, (newValue) => {
  if (newValue) {
    // On large screens show the dialog, or else show the bottom sheet.
    if (isScreenLg()) {
      showDialog.value = true;
    }
    else {
      showBottomSheet.value = true;
    }
  }
  else {
    // Hide both the bottom sheet and dialog when the weather station slug is unset regardless of
    // the screen size.
    showBottomSheet.value = false;
    showDialog.value = false;
  }
});

const handleClose = () => {
  showBottomSheet.value = false;
  showDialog.value = false;
  window.setTimeout(() => uiStore.setUiProperties({ weatherStationSlug: null }), 250);
};
</script>

<template>
  <Dialog
    :show-dialog="showDialog"
    @close="handleClose"
  >
    <Card
      :card-class-names="$style.container"
      :has-body-padding="true"
      :has-body-shadow="true"
      :show-close-button="true"
      @close-button-click="handleClose"
    >
      <template #body>
        <WeatherStationPageContent
          :in-location-page="inLocationPage"
          :in-map-page="inMapPage"
          :in-weather-station-dialog="true"
          :slug="weatherStationSlug"
        />
      </template>
    </Card>
  </Dialog>
  <BottomSheet
    style="/* stylelint-disable */ --visible-height: 85vh;"
    :visible="showBottomSheet"
    @close-bottom-sheet="handleClose"
  >
    <template #content>
      <WeatherStationPageContent
        :in-location-page="inLocationPage"
        :in-map-page="inMapPage"
        :in-weather-station-dialog="true"
        :slug="weatherStationSlug"
      />
    </template>
  </BottomSheet>
</template>

<style module>
.container {
  height: 75vh;
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
  width: 90vw;
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
</style>
